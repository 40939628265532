/** @jsx jsx */
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { jsx } from "theme-ui";
import slugify from "slugify";

import Mats from "../templates/mats";

const MATS_QUERY = graphql`
  query MatsQuery {
    wpPage(slug: { eq: "matrace" }) {
      seo {
        metaDesc
        title
      }
      title
    }
    allDrevocalJson {
      edges {
        node {
          id
          name
          descr
          url
          # cennik
          images {
            hero
            qualities
            taxonomy
          }
          # heroImage {
          #   childImageSharp {
          #     fluid(maxWidth: 512, quality: 100) {
          #       ...GatsbyImageSharpFluid
          #       ...GatsbyImageSharpFluidLimitPresentationSize
          #     }
          #   }
          # }
        }
      }
    }
  }
`;

// const comparePosts = (a, b) => {
//   if (Number(a.slug.split("-")[1]) < Number(b.slug.split("-")[1])) {
//     return -1;
//   } else return 0;
// };

const ProductsPage = props => {
  const [searchResult, setSearchResult] = useState([]);
  const { wpPage, allDrevocalJson } = useStaticQuery(MATS_QUERY);
  const searchParams = new URLSearchParams(props.location.search);
  let results =
    (searchResult.length && searchResult) ||
    allDrevocalJson?.edges?.map(x => x.node);

  // creates here duplicates
  // results = [...new Set(results.map(x => x.slug.split("-")[0]))].flatMap(x =>
  //   results.filter(y => y.slug.includes(x)).sort(comparePosts)
  // );

  if (props.location.search.length) {
    results = results.filter(
      y =>
        slugify(y.productCategories.nodes[0].name).toLowerCase() ===
        searchParams.get("kategoria")
    );
  }

  return (
    <Mats wpPage={wpPage} results={results} setSearchResult={setSearchResult} />
  );
};

export default ProductsPage;
